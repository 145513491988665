import React from "react";
import Navigate from "../navigate";
import cx from "classnames";

function CategoryTitle(props) {
    const className = cx(
        "flex text-denim justify-between font-bold mb-2 text-lg",
        props.className,
        props.className?.includes("normal-case") ? '' : 'uppercase'
    );

    const render = () => {
        return <div className={className} data-datocms-noindex>
            <div className="">{props.title}</div>
            {props.showArrow && <div className="">></div>}
        </div>;
    }
    return <>
        {props.link
            ? <Navigate href={props.link} className="popular-posts-top-link">
                {render()}
            </Navigate>
            : render()
        }
    </>;
}

export default CategoryTitle;
